import page1 from 'assets/images/design_projects/puppet_animation/pages/spread1.png'
import page2 from 'assets/images/design_projects/puppet_animation/pages/spread2.png'
import page3 from 'assets/images/design_projects/puppet_animation/pages/spread3.png'
import page4 from 'assets/images/design_projects/puppet_animation/pages/spread4.png'
import page5 from 'assets/images/design_projects/puppet_animation/pages/spread5.png'
import page6 from 'assets/images/design_projects/puppet_animation/pages/spread6.png'
import page7 from 'assets/images/design_projects/puppet_animation/pages/spread7.png'
import page8 from 'assets/images/design_projects/puppet_animation/pages/spread8.png'
import page9 from 'assets/images/design_projects/puppet_animation/pages/spread9.png'
import page10 from 'assets/images/design_projects/puppet_animation/pages/spread10.png'
import page11 from 'assets/images/design_projects/puppet_animation/pages/spread11.png'

const slideImages = [
    page1,
    page2,
    page3,
    page4,
    page5,
    page6,
    page7,
    page8,
    page9,
    page10,
    page11,
]

export default slideImages;